<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <div class="position-absolute ml-3 font-weight-bolder" v-if="company_name === '' | null"></div>
    <div class="position-absolute ml-3 font-weight-bolder" v-else>{{ company_name }}</div>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale />
      <dark-Toggler class="d-none d-lg-block" />
      <search-bar />
      <cart-dropdown /> -->
      <notification-dropdown />
      <!-- <user-dropdown /> -->
    </b-navbar-nav>

    <b-navbar-nav class="nav align-items-center">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }}
            </p>
            <span class="user-status">{{ user.position_name }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="photo"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="profile()">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout()">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from "@/auth/jwt/useJwt"
import { $themeConfig } from "@themeConfig";
import NotificationDropdown from './NotificationDropdown.vue'

export default {
  components: {
    NotificationDropdown,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  data() {
    return {
      user: [],
      photo: '',
      company_name: ''
    }
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl
    };
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  created() {
    this.$http
      .get("company")
      .then((response) => {
        // console.log(response.data.data);
        this.company_name = response.data.data.name
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {
    this.$http
      .get("user")
      .then((response) => {
        this.user = response.data.data
        const { baseUrl } = $themeConfig.app;
        this.photo = baseUrl
        this.photo += 'storage/profil_photos/'
        if(response.data.data.image === '' || null){
          this.photo += 'nophoto.png'
        } else {
          this.photo += response.data.data.image
        }
        sessionStorage.setItem('employeeID', response.data.data.id);
        // console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    logout() {
      const token = localStorage.getItem('accessToken');
      // console.log(token);

      this.$http
      .post("auth/logout")
      .then((response) => {
        localStorage.removeItem('accessToken');
        sessionStorage.removeItem('permissions');
        sessionStorage.removeItem('authenticated');
        this.$router.push("/login");
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    },
    profile() {
      location.href="/profile";
    }
  },
}
</script>
