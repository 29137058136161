export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Attendances',
    route: 'attendances',
    icon: 'UserCheckIcon',
  },
  {
    title: 'Overtimes',
    route: 'overtimes',
    icon: 'ClockIcon',
  },
  {
    title: 'Permits',
    route: 'permits',
    icon: 'CalendarIcon',
  },
  {
    title: 'Permit Quota',
    route: 'permit-quota',
    icon: 'CalendarIcon',
  },
  {
    title: 'Reimbursements',
    route: 'reimbursements',
    icon: 'FileTextIcon',
  },
  {
    title: 'Cash Advance',
    route: 'cash-advance',
    icon: 'PocketIcon',
  },
  {
    title: 'Payrolls',
    route: 'payrolls',
    icon: 'FileIcon',
  },
  {
    title: 'Employees',
    route: 'employees',
    icon: 'UsersIcon',
  },
  {
    title: 'Company',
    route: 'company',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Setttings',
    route: null,
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Job Positions',
        route: 'jobs',
        icon: 'CircleIcon',
      },
      {
        title: 'Organizations',
        route: 'organizations',
        icon: 'CircleIcon',
      },
      {
        title: 'Divisions',
        route: 'divisions',
        icon: 'CircleIcon',
      },
      {
        title: 'Departments',
        route: 'departments',
        icon: 'CircleIcon',
      },
      {
        title: 'Employee Status',
        route: 'employee-status',
        icon: 'CircleIcon',
      },
      {
        title: 'Employee PTKP',
        route: 'employee-ptkp',
        icon: 'CircleIcon',
      },
      {
        title: 'User Roles',
        route: 'roles',
        icon: 'CircleIcon',
      },
    ]
  }
]
