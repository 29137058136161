<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="number"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ number }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <!-- <b-link
        v-for="notification in notifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link> -->

      <!-- System Notifications -->
      <b-link
        v-for="notification in notif"
        :key="notification.subtitle"
        @click="readByID(notification.id)"
      >
        <b-media v-bind:class="notification.readed === 0 ? '' : 'bg-light-secondary'">
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>

    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="readAll()"
      block
    >Read all notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      number: null,
      notif: [],
      storage: []
    }
  },
  setup() {
    const systemNotifications = [
      {
        title: 'High memory usage',
        subtitle: 'BLR Server using high memory',
        type: 'light-warning',
        icon: 'InfoIcon'
      },
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      systemNotifications,
      perfectScrollbarSettings
    }
  },
  created() {
    this.$http
    .get("notifications")
    .then((response) => {
      console.log(response.data.data)
      const $receivedDatas = response.data.data
      var i
      for(i=0; i<$receivedDatas.length; i++) {
        var $datas = {
          title: $receivedDatas[i].title,
          subtitle: $receivedDatas[i].message,
          type: 'light-warning',
          icon: 'InfoIcon',
          id: $receivedDatas[i].id,
          tag: $receivedDatas[i].tag,
          destination: $receivedDatas[i].destination,
          readed: $receivedDatas[i].readed
        }
        this.notif.push($datas)

        if($receivedDatas[i].readed === 0) {
          var $dataNumber = {
            desc: 'readed'
          }
          this.storage.push($dataNumber)
        }
      }
      this.number = this.storage.length
      // console.log(this.number)
    }).catch((errors) => {
      this.errMessage = errors.response.data.message
      console.log(errors.response)
    })
  },
  methods: {
    readByID(id) {
      this.$http
      .get("notification/"+id)
      .then((response) => {
        location.href = response.data.data.destination
        // console.log(response.data.data)
      }).catch((errors) => {
        this.errMessage = errors.response.data.message
        console.log(errors.response)
      })
    },
    readAll() {
      this.$http
      .get("notifications/read")
      .then((response) => {
        location.reload();
        // console.log(response.data.data)
      }).catch((errors) => {
        this.errMessage = errors.response.data.message
        console.log(errors.response)
      })
    },
  },
}
</script>